import styled from "styled-components"
export const PorQueIntramet = styled.div`
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  .container {
    height: 100%;
    display:grid;
    align-items:center;
  }
  .card{
    padding:0;
  }
  .card-body {
    width: 50%;
    &-title {
      color: ${({ theme }) => theme.colors.amarillo};
      font-size: ${({ theme }) => theme.fontsize.extralarge};
      margin-bottom: 0;
      margin-top: 0;
    }
    &-text {
      color: ${({ theme }) => theme.colors.gris};
      font-style: italic;
      font-size: ${({ theme }) => theme.fontsize.large};
      margin-top: 0;
    }
  }
  @media (max-width: 640px) {
    .card-body {
      width: 100%;
      text-align: center;
    }
  }
`
