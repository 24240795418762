import BusUrbano from "../assets/images/bus_urbano.jpg"
import BusInterprovincial from "../assets/images/bus_interprovincial.jpg"
import BusTraslado from "../assets/images/bus_traslado.jpg"
const prefix='/bus'
export default [
  {
    title: "BUSES URBANOS",
    text: `Vehículos modernos y resistentes
        para transportar pasajeros en la ciudad.
        "Recomendados por transportistas
        peruanos".`,
    img: BusUrbano,
    url:`${prefix}/urbano`,
    details: [
      {
        title: `INNOVACIÓN:`,
        text: `El sistema modular que empleamos
                nos permite personalizar los vehículos manteniendo
                calidad y costos razonables para nuestros
                clientes.`,
      },
      {
        title: `LÍDERES EN EL SUR:`,
        text: `Tenemos una trayectoria de
                14 años atendiendo las necesidades de conductores
                en diferentes partes del Perú en especial
                del sur del país. Estamos cerca para atender
                cualquier requerimiento.`,
      },
    ],
  },
  {
    title: "BUSES INTERPROVINCIALES",
    text: `Experiencia en carrocerías sólidas
        que se adaptan a las carreteras del Perú`,
    img: BusInterprovincial,
    url:`${prefix}/interprovincial`,
    details: [
      {
        title: `RENDIMIENTO:`,
        text: `La solidez de nuestros vehículos
                los hace ideales para afrontar los desafíos de las
                carreteras del Perú. Nos adaptamos a los requerimientos
                de forma y desempeño típicos de los
                buses para transporte interprovincial.`,
      },
      {
        title: `STANDARD INTERNACIONAL:`,
        text: `Somos una fábrica
                de carrocerías que trabaja en base a altos estándares
                lo que tiene como resultado un vehículo
                compacto, seguro y elegante.`,
      },
    ],
  },
  {
    title: "BUSES TRASLADO DE PERSONAL DE MINA",
    text: `Vehículos sólidos y cómodos
        adaptables para todo tipo de clima`,
    img: BusTraslado,
    url:`${prefix}/para-personal-de-mina`,
    details: [
      {
        title: `ESTRUCTURA y COMODIDAD:`,
        text: `Intramet pone especial
                atención en la estructura de los buses de
                transporte para personal de mina para que
                tengan buen rendimiento en diferentes carreteras
                y caminos. Contamos además con unidades
                con asientos cómodos, ideales para los pasajeros.`,
      },
      {
        title: `SEGURIDAD Y GARANTIA:`,
        text: `Ponemos a su disposición
                nuestra experiencia en carrocerías a través
                de estos 14 años en el mercado y mantenemos
                nuestro compromiso de atender las necesidades
                posteriores que puedan surgir.`,
      },
    ],
  },
]
