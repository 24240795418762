import React from "react"
import { Block,BlockWrapper } from "./style"
import TpBuses from "../../../data/tiposBuses"
const TipoBuses = () => {
  return (
    <>
      {TpBuses.map((tpBus, key) => {
        const { title, text, img, details } = tpBus
        return (
          <BlockWrapper key={key} className="section fp-auto-height">
          <Block  >
            <div className="col1">
              <img src={img} alt={title} />
            </div>
            <div className="col2">
              <div className="row1">
                <h3>{title}</h3>
                <p>{text}</p>
              </div>
              <div className="row2">
                {details.map((det, key) => {
                  const { title, text } = det
                  return (
                    <div key={key}>
                      <h4>{title}</h4> <span>{text}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </Block>
          </BlockWrapper>
        )
      })}
      </>
  )
}
export default TipoBuses
