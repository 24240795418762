import styled from "styled-components"
export const Block = styled.div`
  display: flex;
  height: 90vh;
  .col1 {
    flex-basis: 60%;
  }
  .col2 {
    align-self: center;
    padding: 3rem;
    flex-basis: 40%;
    max-width: 30rem;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .row1 {
    text-align: center;
    h3 {
      font-size: ${({ theme }) => theme.fontsize.large};
      margin-top: 0;
    }
    p {
      font-weight: bold;
    }
  }
  .row2 {
    h4 {
      display: inline-block;
      margin-bottom: 0;
      font-family: "Aller";
    }
  }
 
  @media (max-width: 800px ) {
    flex-direction: column;
    /* height: auto; */
    .col1,
    .col2 {
      flex-basis: auto;
    }
    .col1 {
      height: 20vh;
    }
    .col2 {
      height: 80vh;
      max-width: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* display: flex; */
      /* flex-direction: column; */
      /* justify-content: center; */
    }
  }
`
export const BlockWrapper = styled.div`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.azul};
    color: ${({ theme }) => theme.colors.amarillo};
  }
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.amarillo};
    color: ${({ theme }) => theme.colors.azul};
  }
`
